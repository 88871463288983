/**
 * Device 设备知识
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Message, Modal, Button, Dropdown, DropdownMenu, DropdownItem,Select,Option } from "view-design";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Select', Select);
Vue.component('Option', Option);
import { getDeviceClassAllService,
    addDeviceClassService,
    updateDeviceClassService,
    upgradeDeviceClassService,
    delDeviceClassService,
    deviceTypeMoveService} from '@/service/device-class-service'
import { mapMutations, mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
const Device = {
    name:'Device',
    computed: {
        ...mapState({
            selectedMenuId: state => state.system.selectedMenuId, //选中的菜单Id
            menuVisible: state => state.system.menuVisible, //菜单显示状态
        }),
    },
    data() {
        return {
            windowWidth:window.innerWidth, //获取窗口宽度
            deviceModalVisible:false, //设备分类modal弹框可显示
            deviceLoading:false, //设备分类modal弹框 loading
            deviceListArray:[], //设备分类数据
            deviceTypeList:[], //设备分类二级数据
            deviceName:'', //设备名称
            deviceId:'', //设备id
            deviceType:'', //设备分类
            btnStatus:false, //按钮状态
        }
    },
    created() {
        if(this.selectedMenuId.length!==0){
            this.$router.push('/device/list')
        }
        if(this.selectedMenuId.length === 0){
            this.SET_SELECTED_MENU_ID([-1,'',''])
        }
        this.onDeviceClassAll().then()
        console.log(this.selectedMenuId)
    },
    methods: {
        ...mapMutations('system',['SET_CLASSIFY_MENU_ARRAY','SET_SELECTED_MENU_ID']),

        /**
         * 跳转携带id
         * @param id
         */
        onLinks(deviceId,childrenId,childId){
            this.SET_SELECTED_MENU_ID([-1,childrenId,childId])
            if(this.$route.path!=='/device/list'){
                this.$router.push('/device/list')
            }else{
                $event.$emit(EVENT_NAME.DEVICE_MENU_LIST)
            }
        },
        /**
         * 添加根结点设备
         */
        onAddDevice(){
            this.btnStatus = true
            this.deviceModalVisible = true
            this.deviceName = ''
            this.deviceId = ''
        },
        /**
         * 添加设备分类验证
         */
        onAddDeviceCheck(){
            if(this.deviceName === ''){
                Message.error('请输入设备名称！')
                return;
            }
            this.btnStatus?this.onAddDeviceClass().then():this.onUpdateDeviceClass().then()

        },
        /**
         * 添加二级设备
         * @param id
         */
        onChildAddDevice(id){
            this.btnStatus = true
            this.deviceModalVisible = true
            this.deviceName = ''
            this.deviceId = id
        },
        /**
         * 修改设备分类
         * @param id
         */
        onUpdateDevice(obj,type){
            this.btnStatus = false
            this.deviceModalVisible = true
            this.deviceName = obj.label
            this.deviceId = obj.value
            this.deviceType = type
        },
        /**
         * 升级设备分类
         * @param id
         */
        onUpgradeDevice(id){
            this.onUpgradeDeviceClass(id).then()
        },
        /**
         * 删除设备
         * @param id
         */
        onDelDevice(id){
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除吗？</p>',
                loading:true,
                onOk: () => {
                    this.onDelDeviceClass(id).then()
                }
            });
        },
        /**
         * 移动设备分类
         * @param id
         * @param type
         */
        onSortableDevice(id,type){
            this.onDeviceTypeMoveService(id,type).then()
        },
        /**
         * 添加设备分类
         * @returns {Promise<void>}
         */
        async onAddDeviceClass(){
            try {
                this.deviceLoading = true
                let params = {
                    parentId:this.deviceId,
                    name:this.deviceName,
                }
                const { code } = await addDeviceClassService(params)
                if(code === 'SUCCESS'){
                    this.deviceName = ''
                    this.deviceId = ''
                    Message.success('添加成功！')
                    this.onDeviceClassAll().then()
                    this.deviceModalVisible = false
                }else if(code === 'EQUIPMENT_CLASSIFICATION_NODE_REPEAT_NAME'){
                    Message.error('请勿重复添加设备名称！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceLoading = false

            }catch (error){
                this.deviceLoading = false
                console.log(error)
            }
        },
        /**
         * 修改设备分类
         * @returns {Promise<void>}
         */
        async onUpdateDeviceClass(){
            try {
                this.deviceLoading = true
                let params = {
                    id:this.deviceId,
                    newName:this.deviceName,
                    parentId:this.deviceType
                }
                const { code } = await updateDeviceClassService(params)
                if(code === 'SUCCESS'){
                    this.deviceName = ''
                    this.deviceId = ''
                    Message.success('修改成功！')
                    this.onDeviceClassAll().then()
                    this.deviceModalVisible = false
                }else if(code === 'EQUIPMENT_CLASSIFICATION_NODE_REPEAT_NAME'){
                    Message.error('请勿重复添加设备名称！')
                }else if(code === 'EQUIPMENT_CLASSIFICATION_NODE_HAVE_CHILD_NODE_FAIL'){
                    Message.error('您修改的分类下存在其他分类，请移除后重试！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceLoading = false

            }catch (error){
                this.deviceLoading = false
                console.log(error)
            }
        },
        /**
         * 升级设备分类
         * @returns {Promise<void>}
         */
        async onUpgradeDeviceClass(id){
            try {
                let params = {
                    id:id,
                }
                const { code } = await upgradeDeviceClassService(params)
                if(code === 'SUCCESS'){
                    Message.success('升级成功！')
                    this.onDeviceClassAll().then()
                }else if(code === 'EQUIPMENT_CLASSIFICATION_NODE_REPEAT_NAME'){
                    Message.warning('目录名称重复，请更改后重试！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除设备分类
         * @returns {Promise<void>}
         */
        async onDelDeviceClass(id){
            try {
                this.deviceLoading = true
                let params = {
                    id:id,
                }
                const { code } = await delDeviceClassService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.onDeviceClassAll().then()
                }else if(code === 'EQUIPMENT_CLASSIFICATION_NODE_HAVE_CHILD_NODE_FAIL'){
                    Message.error('此分类下存在其他分类，不可删除！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.deviceLoading = false
                Modal.remove();
            }catch (error){
                Modal.remove();
                this.deviceLoading = false
                console.log(error)
            }
        },
        /**
         * 获取所有设备分类
         */
        async onDeviceClassAll() {
            try {
                const { code,data } = await getDeviceClassAllService()
                if(code === 'SUCCESS'){
                    for(let i in data){
                        data[i].name = '目录'
                        data[i].visible = true
                        data[i].id = -1
                        if(data[i].childNode.length!==0){
                            for(let j in data[i].childNode){
                                data[i].childNode[j].visible = true
                            }
                        }
                    }
                    this.deviceListArray = this.onReplaceAttribute(data)
                    this.deviceTypeList = this.deviceListArray[0].children
                    this.SET_CLASSIFY_MENU_ARRAY(this.deviceListArray)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 设备分类移动排序
         */
        async onDeviceTypeMoveService(id,moveType) {
            try {
                let params = {
                    id:id,
                    moveType:moveType,
                }
                const { code } = await deviceTypeMoveService(params)
                if(code === 'SUCCESS'){
                    this.onDeviceClassAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.value = list.id;
                newData.label = list.name;
                newData.visible = list.visible;
                newData.children = list.childNode;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
    },
    destroyed () {
    }
}
export default Device
