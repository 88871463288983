<template>
    <div class="layout-device">

        <!--s: Device menu-->
        <div class="device-menu">
            <div class="menu-system">
                <div class="menu-top">
                    <h1 class="title" @click="onLinks('','','')">设备目录</h1>
                    <a href="javascript:" @click="onAddDevice"><Icon custom="circle" /></a>
                </div>
<!--                <div class="menu-input">-->
<!--                    <Input suffix="ios-search" placeholder="请输入" class="input"/>-->
<!--                </div>-->
            </div>
            <div class="menu-list">
                <div class="list-item" :key="'child1'+key" v-for="(deviceList,key) in deviceTypeList">
                    <div class="item-thead" :style="deviceList.children.length === 0?'padding-left:24px':''">
                        <a href="javascript:">
                            <Icon  v-if="deviceList.children.length!==0" :type="deviceList.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon icon-arrow"  @click="deviceList.visible = !deviceList.visible"/>
                            <Icon :custom="deviceList.visible && deviceList.children.length!==0?'menu-open-folder':'menu-folder'"></Icon>
                            <p :class="selectedMenuId && selectedMenuId[1] === deviceList.value?'active':''" @click="onLinks('',deviceList.value,deviceList.value)">{{ deviceList.label }}</p>
                        </a>
                        <div class="child-system">
                            <Dropdown :transfer="true">
                                <a href="javascript:">
                                    <Icon custom="more"/>
                                </a>
                                <DropdownMenu slot="list" class="system-menu">
                                    <DropdownItem>
                                        <a href="javascript:" @click="onUpdateDevice(deviceList)">编辑</a>
                                    </DropdownItem>
                                    <DropdownItem v-if="key !== 0">
                                        <a href="javascript:" @click="onSortableDevice(deviceList.value,1)">上移</a>
                                    </DropdownItem>
                                    <DropdownItem v-if="key+1 !== deviceList.children.length">
                                        <a href="javascript:" @click="onSortableDevice(deviceList.value,2)">下移</a>
                                    </DropdownItem>
                                    <DropdownItem v-if="key !== 0">
                                        <a href="javascript:" @click="onSortableDevice(deviceList.value,3)">置顶</a>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <a href="javascript:" @click="onDelDevice(deviceList.value)">删除</a>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <a href="javascript:" class="href" @click="onChildAddDevice(deviceList.value)"><Icon custom="circle" class="icon"/></a>
                        </div>
                    </div>
                    <div class="item-body" v-if="deviceList.visible">
                        <div class="item-child" :key="'child2'+key" v-for="(children,key) in deviceList.children">
                            <div class="child-thead" :style="children.children.length === 0?'padding-left:45px':''">
                                <a href="javascript:" class="href">
                                    <Icon v-if="children.children.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon icon-arrow" />
                                    <Icon :custom="children.visible && children.children.length !== 0?'menu-open-folder':'menu-folder'"></Icon>
                                    <p :class="selectedMenuId && selectedMenuId[2] === children.value?'active':''" @click="onLinks(deviceList.value,children.value,children.value)">{{ children.label }}</p>
                                </a>
                                <div class="child-system">
                                    <Dropdown :transfer="true">
                                        <a href="javascript:">
                                            <Icon custom="more"/>
                                        </a>
                                        <DropdownMenu slot="list" class="system-menu">
                                            <DropdownItem>
                                                <a href="javascript:" @click="onUpdateDevice(children)">编辑</a>
                                            </DropdownItem>
                                            <DropdownItem v-if="key !== 0">
                                                <a href="javascript:" @click="onSortableDevice(children.value,1)">上移</a>
                                            </DropdownItem>
                                            <DropdownItem v-if="key+1 !== deviceList.children.length">
                                                <a href="javascript:" @click="onSortableDevice(children.value,2)">下移</a>
                                            </DropdownItem>
                                            <DropdownItem v-if="key !== 0">
                                                <a href="javascript:" @click="onSortableDevice(children.value,3)">置顶</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="javascript:" @click="onDelDevice(children.value)">删除</a>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
<!--                                    <a href="javascript:" class="href" @click="onChildAddDevice(children.value)"><Icon type="ios-add-circle-outline" class="icon"/></a>-->
                                </div>
                            </div>
<!--                            <div class="child-body" v-if="children.visible">-->
<!--                                <ul>-->
<!--                                    <li :key="'child3'+key" v-for="(child,key) in children.children">-->
<!--                                        <a href="javascript:" class="href" :class="selectedMenuId && selectedMenuId[2] === child.value?'active':''" @click="onLinks(deviceList.value,children.value,child.value)"><Icon type="ios-copy-outline" class="icon"/>{{ child.label }}</a>-->
<!--                                        <div class="child-system">-->
<!--                                            <Dropdown :transfer="true">-->
<!--                                                <a href="javascript:">-->
<!--                                                    <Icon custom="more"/>-->
<!--                                                </a>-->
<!--                                                <DropdownMenu slot="list" class="system-menu">-->
<!--                                                    <DropdownItem>-->
<!--                                                        <a href="javascript:" @click="onUpgradeDevice(child.value)">升级</a>-->
<!--                                                    </DropdownItem>-->
<!--                                                    <DropdownItem>-->
<!--                                                        <a href="javascript:" @click="onUpdateDevice(child,children.value)">编辑</a>-->
<!--                                                    </DropdownItem>-->
<!--                                                    <DropdownItem v-if="key !== 0">-->
<!--                                                        <a href="javascript:" @click="onSortableDevice(child.value,1)">上移</a>-->
<!--                                                    </DropdownItem>-->
<!--                                                    <DropdownItem v-if="key+1 !== children.children.length">-->
<!--                                                        <a href="javascript:" @click="onSortableDevice(child.value,2)">下移</a>-->
<!--                                                    </DropdownItem>-->
<!--                                                    <DropdownItem v-if="key !== 0">-->
<!--                                                        <a href="javascript:" @click="onSortableDevice(child.value,3)">置顶</a>-->
<!--                                                    </DropdownItem>-->
<!--                                                    <DropdownItem>-->
<!--                                                        <a href="javascript:" @click="onDelDevice(child.value)">删除</a>-->
<!--                                                    </DropdownItem>-->
<!--                                                </DropdownMenu>-->
<!--                                            </Dropdown>-->
<!--                                        </div>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--e: Device menu-->

        <!--s: Device content-->
        <div class="device-content" :style="menuVisible?'width: calc(100% - 560px);':'width: calc(100% - 310px);'">
            <router-view />
        </div>
        <!--e: Device content-->

        <Modal
            v-model="deviceModalVisible"
            :title="btnStatus?'添加设备目录':'修改设备目录'"
            footer-hide>
            <div class="device-modal">
                <span class="label">名称：</span>
                <Input v-model="deviceName" maxlength="10" show-word-limit placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal" v-if="!btnStatus">
                <span class="label">目录：</span>
                <Select v-model="deviceType" clearable  style="width:300px">
                    <Option v-for="(type,key) in deviceTypeList" v-show="deviceId !== type.value" :value="type.value" :key="key">{{ type.label }}</Option>
                </Select>
            </div>
            <div class="device-footer">
                <Button @click="deviceModalVisible = false">取 消</Button>
                <Button type="primary" :loading="deviceLoading" @click="onAddDeviceCheck">确 定</Button>
            </div>
        </Modal>

    </div>
</template>
<script>
import Device from './device'
export default Device
</script>
<style lang="less">
@import "device";
</style>
